export default {
  baseURL: '/merchant',
  recaptchaSiteKey: '6Lc3n94lAAAAAA-ZM8c9220rOB_AUJrTx_xQsyyi',
  paayLibrary: {
    scriptUrl: '/ab-libraries/paay.js',
    verifyUrl: 'https://api.3dsintegrator.com/v2.2',
    verbose: false,
    logUrl: '/paaylog',
  },
  env: 'prod',
  digitalWalletScript: '/ab-libraries/digital-wallets.js',
  googlePayMerchantId: 'BCR2DN4TQTVP7SSA',
}

