<template>
  <div v-if="Array.isArray(item)">
    <custom-component v-for="component in item"
                      :item="component"
                      @input="$emit('input', $event)"/>
  </div>
  <validation-provider
      v-else-if="item.classes && item.classes.some(cls => (['input-wrap', 'textarea', 'input-group'].includes(cls))) && !item.classes.some(cls => ['select-wrap', 'select-wrap-children'].includes(cls))"
      v-show="showComponent(item)"
      tag="div"
      :rules="setRules(item.components)"
      :class="item.classes"
      :name="wrapperName(item)"
      :vid="vid(item.components)"
      v-slot="{ errors }">
    <custom-component
        v-for="component in filteredComponents(item)"
        :item="component"
        v-model="dataValue"
    />
    <div class="error-wrapper">
      <span class="input-error">{{errors[0]}}</span>
    </div>
  </validation-provider>
  <validation-provider
      v-else-if="item.classes && item.classes.some(cls => cls === 'select-wrap') && !item.classes.some(cls => cls === 'amount-wrap')"
      v-show="showComponent(item)"
      tag="div"
      :rules="setRules(item.components[0] && item.components[0].components)"
      :class="[item.classes]"
      :name="wrapperName(item.components[0])"
      :vid="vid(item.components[0] && item.components[0].components)"
      v-slot="{ errors }">
    <custom-component
        v-for="component in filteredComponents(item)"
        :item="component"
        v-model="dataValue"
    />
    <div class="error-wrapper">
      <span class="input-error">{{errors[0]}}</span>
    </div>
  </validation-provider>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'select-wrap-children')"
       v-bind="item.attributes"
       :options="($parent.id === 'accountType' && accountTypes) ||
                ($parent.id === 'billingState' && states) ||
                ($parent.id === 'shippingState' && states) ||
                (['expiryMonth','dafExpiryMonth'].includes($parent.id) && months) ||
                (['expiryYear','dafExpiryYear'].includes($parent.id) && years)"
       :class="item.classes"
       @input="$emit('input', $event.target.value)">
    <custom-component
        v-for="component in filteredComponents(item)"
        :item="component"
        v-model="dataValue"
        :data="{ selectType: $parent && $parent.id }"
        @input="$emit('input', $event)"
    />
  </div>
  <dropdown
      v-else-if="item.tagName === 'ul' && item.classes && item.classes.some(cls => cls === 'dropdown') && data.selectType === 'billingState'"
      :options="states"
      v-bind="item.attributes"
      :class="setClasses(item, data.selectType)"
      v-model="billingInfo[data.selectType]"
      :select-type="data.selectType"
      @input="$emit('input', $event)"
  />
  <dropdown
      v-else-if="item.tagName === 'ul' && item.classes && item.classes.some(cls => cls === 'dropdown') && data.selectType === 'shippingState'"
      :options="states"
      v-bind="item.attributes"
      :class="setClasses(item, data.selectType)"
      v-model="shippingInfo[data.selectType]"
      :select-type="data.selectType"
      @input="$emit('input', $event)"
  />
  <dropdown
      v-else-if="item.tagName === 'ul' && item.classes && item.classes.some(cls => cls === 'dropdown')
      && ['accountType', 'expiryMonth', 'expiryYear', 'dafExpiryMonth', 'dafExpiryYear'].includes(data.selectType)"
      :options="(data.selectType === 'accountType' && accountTypes) ||
                (['expiryMonth','dafExpiryMonth'].includes(data.selectType) && months) ||
                (['expiryYear','dafExpiryYear'].includes(data.selectType) && years) || []"
      v-bind="item.attributes"
      :class="setClasses(item, data.selectType)"
      v-model="generalInfo[deDafifyField(data.selectType)]"
      :select-type="data.selectType"
      @input="$emit('input', $event)"
  />
  <ul v-else-if="item.tagName === 'ul' && item.classes && item.classes.some(cls => cls === 'dropdown')"
      v-show="$root.show[data.selectType]"
      v-bind="item.attributes"
      :class="[setClasses(item, data.selectType)]">
    <custom-component
        v-for="component in filteredComponents(item)"
        :item="component"
        :value="dataValue"
        :data="{ selectType: data.selectType }"
        @input="$emit('input', $event)"
    />
  </ul>
  <li v-else-if="item.tagName === 'li'"
      v-bind="item.attributes"
      :name="item.attributes.name"
      :value="item.attributes.value"
      @click="selectOption({ key: item.attributes.name, value: item.attributes.value }, data.selectType)">
    {{ item.content }}
    <custom-component
        v-for="component in filteredComponents(item)"
        :item="component"
        v-model="dataValue"
        @input="$emit('input', $event)"
    />
  </li>
  <label v-else-if="item.type === 'label'"
         v-bind="item.attributes"
         :class="item.classes">{{ labelName(item) }}
  </label>
  <input v-else-if="['accountType', 'expiryYear', 'expiryMonth', 'dafExpiryYear', 'dafExpiryMonth'].includes(item && item.attributes && item.attributes.id)"
         v-bind="omit(item.attributes, ['required'])"
         :readonly="item.attributes.id === 'accountType'"
         :name="item.attributes && item.attributes.id"
         @click="show(item)"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[deDafifyField(item.attributes.id)]"
         :class="setClasses(item)" />
  <input v-else-if="item && item.attributes && item.attributes.id === 'description'"
         v-bind="item.attributes.readonly === '1' ? { readonly: 'readonly' } : ''"
         :value="descriptionValue(value, item)"
         :name="item.attributes && item.attributes.id"
         @click="hasDropdown && show(item)"
         @input="descriptionValue($event.target.value, item, $event)"
         @blur="setDefaultDescription(value, item)"
         :class="setClasses(item)" />
  <input v-else-if="['remTransNumber', 'schedule'].includes(item && item.attributes && item.attributes.id)"
         v-bind="omit(item.attributes, ['required']), (item.attributes.id === 'amount' && item.attributes.readonly) || item.attributes.id !== 'amount' ? { readonly: 'readonly' } : ''"
         :value="recurringValue(value, item.attributes.id)"
         :name="item.attributes && item.attributes.id"
         :disabled="oneTimeRecurring(item)"
         :class="[setClasses(item), {'disabled': oneTimeRecurring(item), 'text-indent': $root.showCurrencySymbol }]"
         @click="show(item)"
         @input="onValueInput(item)" />
  <input v-else-if="item && item.attributes && item.attributes.id === 'billingState'"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @click="show(item)"
         @input="$emit('input', $event.target.value)"
         v-model="billingInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="item && item.attributes && item.attributes.id === 'shippingState'"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @click="show(item)"
         @input="$emit('input', $event.target.value)"
         v-model="shippingInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="['remTransNumber', 'schedule'].includes(item && item.attributes && item.attributes.id)"
         v-bind="omit(item.attributes, ['required']), (item.attributes.id === 'amount' && item.attributes.readonly) || item.attributes.id !== 'amount' ? { readonly: 'readonly' } : ''"
         :value="recurringValue(value, item.attributes.id)"
         :name="item.attributes && item.attributes.id"
         :disabled="oneTimeRecurring(item)"
         :class="[setClasses(item), {'disabled': oneTimeRecurring(item), 'text-indent': $root.showCurrencySymbol }]"
         @click="show(item)"
         @input="onValueInput(item)" />
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'payment-type-wrap')" v-bind="item.attributes" class="payment-type-wrap">
    <div v-for="tab in getRenderedTabs(item)"
      :class="{'payment-type-button': true, [`payment-type-${tab.value}`]: true, active: activeTab === tab.value }"
      v-bind="tab.attributes"
      @click="togglePaymentType(tab.value)">
      <custom-component v-for="component in tab.components" :item="component"/>
    </div>
  </div>
  <input v-else-if="item.attributes && item.attributes.type === 'radio' && item.attributes.name === 'paymentType'"
         v-bind="item.attributes"
         :class="item.classes" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes && ['card', 'dafCard'].includes(item.attributes.id)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         v-mask="cardMask"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo.cardNumber"
         :class="setClasses(item, 'cardNumber')" />
    <div v-else-if="item.attributes && item.attributes.id === 'dafLogos' && this.$root.availableDafs.length" class="mb-2 mt-n3 text-right">
      <template v-for="daf in this.$root.availableDafs">
        <img
            v-if="activeTab === 'daf'"
            :key="daf"
            :src="`/images/${dafCardMap[daf].imageSrc}`"
            :alt="dafCardMap[daf].displayName"
            class="daf-card-logo" />
      </template>
    </div>
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes && ['cvv2', 'dafCvv2'].includes(item.attributes.id)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         v-mask="cvvMask"
         :maxlength="cvv2Length"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[deDafifyField(item.attributes.id)]"
         :class="setClasses(item)" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes && item.attributes.id === 'accountNumber'"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <!--  v-model is for passing value both up and down, @input is to pass up specifically for validation -->
  <input v-else-if="item.type === 'checkbox' && item.attributes.id === 'acceptedTerms'"
         type="checkbox"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="item.type === 'checkbox'"
         type="checkbox"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes.id.startsWith('custom')"
         :type="setInputType(item)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="customFields[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes.id.startsWith('billing')"
         :type="setInputType(item)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="billingInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input') && item.attributes.id.startsWith('shipping')"
         :type="setInputType(item)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="shippingInfo[item.attributes.id]"
         :class="setClasses(item)" />
  <input v-else-if="item.attributes && item.attributes.id === 'recurringCustomTimes'"
         v-bind="omit(item.attributes, ['required', 'readonly'])"
         :type="setInputType(item)"
         :placeholder="0"
         @input="$emit('input', { key: `${$event.target.value} Times`, value: recurringInfo['remTransNumber'] = $event.target.value })"
         @change="$root.show['remTransNumber'] = false"
         :value="recurringCustomTimes"
         :class="setClasses(item)" />
  <input v-else-if="(item.type === 'input' || item.tagName === 'input')"
         :type="setInputType(item)"
         v-bind="omit(item.attributes, ['required'])"
         :name="item.attributes && item.attributes.id"
         @input="$emit('input', $event.target.value)"
         v-model="generalInfo[deDafifyField(item.attributes.id)]"
         :class="setClasses(item)" />
  <img v-else-if="(item.type === 'image' || item.type === 'img') && item.attributes.id === 'merchantLogo'"
       v-bind="item.attributes"
       :src="merchantLogo"
       :class="item.classes" />
  <img v-else-if="item.type === 'image' || item.type === 'img'"
       v-bind="item.attributes"
       :class="item.classes" />
  <textarea v-else-if="item.tagName === 'textarea'"
            :name="item.attributes && item.attributes.id"
            v-bind="item.attributes"
            @input="$emit('input', $event.target.value)"
            v-model="generalInfo[item.attributes.id]"
            :class="setClasses(item)" />
  <select v-else-if="item.tagName === 'select'"
          v-bind="item.attributes"
          @input="$emit('input', $event.target.value)"
          v-model="generalInfo[deDafifyField(item.attributes.id)]"
          :class="setClasses(item)" >
    <custom-component v-for="component in filteredComponents(item)"
             :item="component"
             v-model="value"
    />
  </select>
  <div v-else-if="item.tagName === 'button' && item.attributes && item.attributes.id === 'processButton'">
    <button v-if="['card', 'check', 'daf'].includes(activeTab)"
          v-bind="item.attributes"
          type="submit"
          :disabled="$root.loading || !termsAccepted"
          :class="item.classes">
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
    />
    </button>
    <div v-show="activeTab === 'googlepay'" :class="getWalletButtonClasses()">
      <google-pay-button v-pre></google-pay-button>
    </div>
    <div v-show="activeTab === 'applepay'" :class="getWalletButtonClasses()">
      <apple-pay-button type="pay" v-pre></apple-pay-button>
    </div>
  </div>
  <button v-else-if="item.tagName === 'button'"
          v-bind="item.attributes"
          type="button"
          :class="item.classes">{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
             :item="component"
    />
  </button>
  <a v-else-if="item.type === 'link'"
     :href="item.attributes.id === 'website' ? $root.formData.paymentPage.isoWebsite : item.attributes.href"
     v-bind="item.attributes"
     :class="item.classes">{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
             :item="component"
    />
  </a>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'credit-card__brand')"
       :class="item.classes">
    <app-logo :card-number="computedCardNumber" :isDafCard="activeTab === 'daf'"/>
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'card-number-text')"
       :class="item.classes">
    <span v-for="(block, index) of numberFormatted" :key="index">{{block}}{{ index < numberFormatted.length -1 ? ' ': '' }}</span>
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'card-holder-name')"
       :class="item.classes">{{generalInfo.cardholderName || 'Cardholder Name'}}
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'card-title')"
       :class="item.classes">{{generalInfo.name || 'Name on Check'}}
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'card-expire-date')"
       :class="item.classes">{{'Expires: ' + dateFormatted }}
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'account-type-text')"
       :class="item.classes">{{generalInfo.accountType || ' ' }}
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'routing-number-text')"
       :class="item.classes">{{generalInfo.routingNumber || '000000000'}}
  </div>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'account-number-text')"
       :class="item.classes">{{generalInfo.accountNumber || '000000000'}}
  </div>
  <component v-else-if="item.attributes && (['cardWidget', 'checkWidget', 'dafWidget'].includes(item.attributes.id))"
             v-show="showComponent(item)"
             :is="(item.tagName !== 'form' && item.tagName) || (item.type === 'text' && 'div') || item.type || 'div'"
             v-bind="item.attributes"
             :class="item.classes"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
                      @input="$emit('input', $event)"
                      :value="value"/>
  </component>
  <component v-else-if="(item.classes && item.classes.some(cls => ['card-field', 'check-field', 'daf-field'].includes(cls)))"
             v-show="showComponent(item)"
             :is="(item.tagName !== 'form' && item.tagName) || (item.type === 'text' && 'div') || item.type || 'div'"
             v-bind="item.attributes"
             :class="item.classes"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
                      @input="$emit('input', $event)"
                      :value="value"/>
  </component>
  <amount
      v-else-if="item.classes && item.classes.some(cls => cls === 'amount-wrap')"
      :amount-item="item"
      :surcharge-data="$root.surchargeData"
      @input="$emit('input', $event)"
      :value="value"
  />
<!--  terms prop is not needed, its just a way to invoke the function -->
  <span v-else-if="(item.classes && item.classes.some(cls => cls === 'terms-and-conditions-title')
         || item.attributes && item.attributes.id === 'TermsAndConditionsText')"
        v-bind="item.attributes"
        :terms="getTermsText(item)"
        :class="item.classes">
    <custom-component v-for="component in filteredComponents(item)"
                                                  :item="component"
                                                  @input="$emit('input', $event)"
                                                  :value="value"/>
  </span>
  <span v-else-if="item.type === 'textnode'"
             v-bind="item.attributes"
             :class="item.classes"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
                      @input="$emit('input', $event)"
                      :value="value"/>
  </span>
  <span v-else-if="item.classes && item.classes.some(cls => cls === 'icon-btn')"
        v-bind="item.attributes"
        :class="item.classes"
        @click="show($parent && $parent.$parent && $parent.$parent.id)"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
                      @input="$emit('input', $event)"
                      :value="value"/>
  </span>
  <div v-else-if="item.classes && item.classes.some(cls => cls === 'surcharge-message-wrap')"
        v-bind="item.attributes"
        :class="item.classes"
        v-show="activeTab === 'card'"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
                      :item="component"
                      @input="$emit('input', $event)"
                      :value="value"/>
  </div>
  <component v-else
      :is="(item.tagName !== 'form' && item.tagName) || (item.type === 'text' && 'div') || item.type || 'div'"
      v-bind="item.attributes"
      :class="item.classes"
  >{{ item.content }}
    <custom-component v-for="component in filteredComponents(item)"
             :item="component"
             @input="$emit('input', $event)"
             :value="value"/>
  </component>
</template>

<script>
import { mask } from 'vue-the-mask';

import Dropdown from './dropdown.vue';
import Amount from './amount.vue';
import { months, years } from '../utils/dates';
import { states } from '../utils/states';
import {accountTypes, camelToLabel} from '../utils';
import AppLogo from './logo.vue';
import config from '../config';

export default {
  name: 'custom-component',
  props: {
    item: undefined,
    data: undefined,
    value: undefined,
  },
  directives: {
    mask,
  },
  components: { Dropdown, AppLogo, Amount },
  data() {
    return {
      dataValue: this.value,
      states,
      months,
      years,
      accountTypes,
      dafCardMap: {
        pledger: {
          displayName: 'Pledger',
          imageSrc: 'pledger_card.png'
        },
        donors_fund: {
          displayName: 'The Donors Fund',
          imageSrc: 'tdf_card.png'
        }
      }
    };
  },
  methods: {
    onValueInput(item) {
      this.$emit('input', this.recurringInfo[item.attributes.id])
    },
    setDefaultDescription(descriptionValue, item) {
      if (descriptionValue || !item?.attributes?.value) return
      const { content } = item
      const { value } = item.attributes
      this.setDescriptionValue(content && value ? { key: content, value } : content || value || null)
      return this.$emit('input', { key: content, value });
    },
    descriptionValue(value, item, event) {
      if (value === undefined) {
        this.setDefaultDescription(value, item)
      }
      if (event?.type === 'input') {
        this.setDescriptionValue(value)
        this.$emit('input', this.generalInfo['description'])
      }
      return value && value.key || value
    },
    setDescriptionValue(value) {
      this.dataValue = typeof value === 'object' ? value.key : value || null
      this.generalInfo.description = typeof value === 'object' ? value.value : value || null;
      return this.dataValue;
    },
    oneTimeRecurring(item) {
      return item?.attributes?.id === 'schedule' && this.recurringInfo?.remTransNumber === '1'
    },
    recurringValue(value, field) {
      if (field === 'schedule' && this.recurringInfo?.remTransNumber === '1') {
        return null;
      }
      return value && value.key || null;
    },
    selectOption(option, selectType) {
      if (selectType === 'remTransNumber' && option.value === 'numberOfTimes') return
      this.$root.show[selectType] = false;
      selectType === 'description'
          ? this.setDescriptionValue(option)
          : this.recurringInfo[selectType] = option.value;
      this.$emit('input', { key: option.key, value: option.value });
    },
    filteredComponents(item) {
      return item.components?.filter(Boolean) || [];
    },
    showComponent(item) {
      return (item.classes && !item.classes.some(cls => ['check-field', 'card-field', 'daf-field'].includes(cls)))
          || (this.$root.availableTabs.includes('check') && this.activeTab === 'check' && item.classes?.includes('check-field'))
          || (this.$root.availableTabs.includes('card') && this.activeTab === 'card' && item.classes?.includes('card-field'))
          || (this.$root.availableTabs.includes('daf') && this.activeTab === 'daf' && item.classes?.includes('daf-field'));
    },
    wrapperName(item) {
      const labelComponent = item.components?.find(component => component.type === 'label');
      const inputComponent = item.components?.find(component => component.type === 'input');
      return labelComponent?.components?.[0]?.content
          || labelComponent?.content
          || camelToLabel(inputComponent?.attributes?.id);
    },
    vid(components) {
      return components?.find(component => component.type === 'input' ||
          component?.attributes?.type === 'input' ||
          component.tagName === 'input')
          ?.attributes?.id;
    },
    labelName(item) {
      return item.content
          || item.components?.[0]?.content
          || camelToLabel(item.attributes?.for);
    },
    setClasses(item, identifier) {
      const id = identifier || item?.attributes?.id;
      return [item.classes, { 'active': this.generalInfo[this.deDafifyField(id)] || this.customFields[id] || this.billingInfo[id] || this.shippingInfo[id] || (this.recurringInfo?.[id] && !this.oneTimeRecurring(item)) }];
    },
    getWalletButtonClasses() {
      return {
        'wallet-button': true,
        'disabled-wallet-button': this.$root.loading || !this.termsAccepted,
        vertical: this.$root.template === 'standardVertical'
      }
    },
    setInputType(item) {
      if (item.attributes.id === 'email') return 'email';
      if (['billingPhone', 'shippingPhone'].includes(item.attributes.id)) return 'tel';
      return 'text';
    },
    show(item) {
      const id = typeof item === 'object' ? item.attributes.id : item
      this.$root.show[id] = !this.$root.show[id];
    },
    omit(obj, properties) {
      const clone = { ...obj };
      properties.forEach(property => delete clone[property]);
      return clone;
    },
    setRules(components) {
      const component = components?.find(component => ['input', 'textarea'].includes(component.tagName));
      if (!component?.attributes) return;

      const deDafifiedId = this.deDafifyField(component.attributes.id);
      if (component.attributes.id === 'routingNumber' && this.activeTab === 'check') {
        return {
          length: 9,
          required: true,
          numeric: true,
        };
      }
      if (component.attributes.id === 'accountType' && this.activeTab === 'check') {
        return {
          required: true,
        };
      }
      if (component.attributes.id === 'name' && this.activeTab === 'check') {
        return {
          required: true,
        };
      }
      if (component.attributes.id === 'accountNumber' && this.activeTab === 'check') {
        return {
          required: true,
          numeric: true,
        };
      }
      if (component.attributes.id === 'card' && this.activeTab === 'card') {
        return {
          required: true,
          luhn10: true,
          cardRegex: true,
        };
      }
      if (component.attributes.id === 'dafCard' && this.activeTab === 'daf') {
        return {
          required: true,
          luhn10: true,
          dafRegex: [this.$root.availableDafs],
        };
      }
      const isNativeField = !(component.attributes.id.startsWith('daf') ^ this.activeTab === 'daf');
      if(['card', 'daf'].includes(this.activeTab)) {
        if (deDafifiedId === 'expiryYear') {
          return {
            length: 4,
            numeric: true,
            required: isNativeField,
          };
        }
        if (deDafifiedId === 'expiryMonth') {
          return {
            length: 2,
            numeric: true,
            required: isNativeField,
          };
        }
        if (deDafifiedId === 'cvv2') {
          return {
            cvv2: ['@cardNumber'],
            numeric: true,
            required: isNativeField,
          };
        }
      }
      if (deDafifiedId === 'cardholderName') {
        return {
          required: ['card', 'daf'].includes(this.activeTab) && isNativeField && component.attributes.required === 'true'
        }
      }
      if (component.attributes.id === 'remTransNumber') {
        if (this.recurringInfo?.['remTransNumber'] && !['*', '1'].includes(this.recurringInfo['remTransNumber'])) {
          return {
            numeric: true,
            required: component.attributes.required === 'true',
            min_value: 1,
          }
        }
      }
      if (component.attributes.id === 'schedule') {
        if (this.recurringInfo?.['remTransNumber'] && this.recurringInfo['remTransNumber'] !== '1') {
          return {
            required: true
          }
        }
        return {
          required: this.recurringInfo?.['remTransNumber'] && this.recurringInfo['remTransNumber'] !== '1' ? component.attributes.required === 'true' : false
        }
      }

      if (component.attributes.id === 'company' && this.$root.action === 'save') {
        return {
          required: true
        }
      }

      const defaultRequired = ['routingNumber', 'accountNumber', 'accountType', 'name', 'card', 'expiryYear', 'expiryMonth', 'cvv2', 'amount'];
      return {
        email: component.attributes.id === 'email',
        required: !defaultRequired.includes(deDafifiedId) && component.attributes.required === 'true',
      };
    },
    togglePaymentType(tab) {
      this.$root.activeTab = tab
      const fieldsToClear = ['accountType', 'accountNumber', 'routingNumber', 'name', 'cardNumber', 'expiryMonth', 'expiryYear', 'cvv2', 'billZip', 'cardholderName', 'wallet', 'token', 'dafCard', 'dafExpiryMonth', 'dafExpiryYear', 'dafCvv2', 'dafCardholderName']

      const cardFields = document.querySelectorAll('.card-field')
      const checkFields = document.querySelectorAll('.check-field')
      const dafFields = document.querySelectorAll('.daf-field')
      const fieldMap = {
        card: cardFields,
        check: checkFields,
        daf: dafFields
      }
      cardFields.forEach(field => field.classList.add('hidden'))
      checkFields.forEach(field => field.classList.add('hidden'))
      dafFields.forEach(field => field.classList.add('hidden'))
      fieldMap[this.activeTab]?.forEach(field => field.classList.remove('hidden'))

      fieldsToClear.forEach(field => this.$set(this.generalInfo, this.deDafifyField(field), null));
    },
    getTermsText(item) {
      // When returning this.$root.termsAndConditions, we were getting a console error of an infinite loop. Returning the variable resolves it.
      const textArray = []
      item.components.forEach(comp => {
        if (comp.content) {
          textArray.push(comp.content)
        }
      })
      const termsText = textArray.join('')
      this.$root.termsAndConditions = termsText
      return termsText
    },
    hasDropdown(item) {
      return item?.components?.[0]?.components
          ?.find(comp => comp.classes.some(cls => cls === 'dropdown'))
    },
    getRenderedTabs (item) {
      const renderedTabs = {}
      const digitalWalletTabs = Object.keys(this.$root.supportedWallets) || []
      this.filteredComponents(item)
        .forEach(tab => {
          // we need the regex for backward compatibility
          const tabName = tab.attributes?.['tab-name'] || tab.classes.join(' ').match(/payment-type-(?!button)(\w*)/)?.[1]
          if(this.$root.availableTabs.includes(tabName) || digitalWalletTabs.includes(tabName)) renderedTabs[tabName] = tab
        })
        digitalWalletTabs.forEach(dwTab => {
          if (!renderedTabs['card'] || !this.$root.supportedWallets[dwTab]?.enabled) {
            delete renderedTabs[dwTab]
          // for backwards compatibility
          } else if (!renderedTabs[dwTab]) {
            renderedTabs[dwTab] = {
              components: [
                {
                  tagName: 'input',
                  attributes: {
                    type: 'radio',
                    name: 'paymentType',
                  }
                },
                {
                  tagName: 'span',
                  type: 'text',
                  components: [
                    {
                      type: 'textnode',
                      content: `${dwTab[0].toUpperCase()}${dwTab.substring(1).replace('pay', ' Pay')}`
                    }
                  ]
                }
              ]
            }
          }
        })
      const tabArray = Object.entries(renderedTabs).map(([tabName, component]) => {
        return {
          value: tabName,
          ...component
        }
      })
      return tabArray.length > 1 ? tabArray : []
    },
    deDafifyField(field) {
      if (!field?.startsWith('daf')) return field;
      const strippedField = field.replace('daf', '');
      return strippedField.charAt(0).toLowerCase() + strippedField.slice(1);
    },
  },
  computed: {
    recurringCustomTimes() {
      return ['*', '1'].includes(this.recurringInfo?.['remTransNumber']) ? null : this.recurringInfo?.['remTransNumber']
    },
    merchantLogo() {
       return `${config.baseURL}/api/logo-file/${this.$root.formData.paymentPage.slug}`;
    },
    activeTab() {
      return this.$root.activeTab;
    },
    generalInfo() {
      return this.$root.formData.generalInfo;
    },
    customFields() {
      return this.$root.formData.customFields;
    },
    billingInfo() {
      return this.$root.formData.billingInfo;
    },
    shippingInfo() {
      return this.$root.formData.shippingInfo;
    },
    recurringInfo() {
      return this.$root.formData.recurringInfo;
    },
    termsAccepted() {
      if (!this.$root.termsAndConditions) return true;
      return Boolean(this.generalInfo.acceptedTerms);
    },
    computedCardNumber() {
      return this.generalInfo.cardNumber?.split(' ')?.join('');
    },
    cardMask() {
      return this.isAmexCard ? '#### ###### #####' : '#### #### #### ####';
    },
    cvvMask() {
      return '#'.repeat(this.cvv2Length);
    },
    isAmexCard() {
      const cardNumber = this.generalInfo?.cardNumber;
      return this.activeTab !== 'daf' && (cardNumber?.startsWith('37') || cardNumber?.startsWith('34'));
    },
    cvv2Length() {
      return this.isAmexCard ? 4 : 3;
    },
    numberFormatted() {
      if (!this.computedCardNumber) return ['0000', '0000', '0000', '0000']
      return this.generalInfo.cardNumber?.split(' ')
    },
    dateFormatted() {
      const month = this.generalInfo.expiryMonth || 'MM';
      const year = this.generalInfo.expiryYear || 'YYYY';
      return `${month}/${year}`;
    },
  },
};
</script>
