<template>
  <div class="flex-center position-ref full-height">
    <div class="code">{{ errorCode }}</div>
    <div class="message">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: 'error-message',
  props: {
    errorMessage: null,
    errorCode: null,
  },
};
</script>
