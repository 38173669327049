<template>
    <div class="credit-card__brand--icon" :class="{ 'empty': !cardIcon }">
      <img
          v-if="isDafCard && cardIcon"
          :src="`/images/${cardIcon}`"
          width="85"
          height="57"
          alt="card icon"
      />
      <svg-icon v-else-if="cardIcon" color="transparent #fff" :icon="cardIcon" />
  </div>
</template>

<script>
import { cardRegexes, dafRegexes } from '../utils/validationConfig';
import SvgIcon from './../components/SvgIcon.vue';

export default {
  props: {
    edit: Boolean,
    form: { type: Object, default: () => ({}) },
    cardNumber: String,
    isDafCard: Boolean,
  },
  components: { SvgIcon },
  data: () => ({
    cardTypeIcons: {
      'visa': 'visa-icon',
      'mastercard': 'mastercard-icon',
      'amex': 'american-express-icon',
      'discover': 'discover-icon',
      'maestro': 'maestro-icon',
      'diners': 'diners-icon',
      'jcb': 'jcb-icon',
      'donors_fund': 'tdf_card.png',
      'pledger': 'pledger_card.png',
    },
  }),
  computed: {
    cardIcon() {
      const card = Object.entries(this.isDafCard ? dafRegexes : cardRegexes)
          .find(([type, regex]) => regex.test(this.cardNumber));
      return card?.[0] ? this.cardTypeIcons[card[0]] : null;
    },
  }
};
</script>
