<template>
  <ul class="dropdown"
      v-if="showOptions(options, selectType)">
    <li v-for="option in options" @click="selectOption(option, selectType)" :name="option">
      {{option}}
    </li>
  </ul>
</template>

<script>
import { states } from '../utils/states';
import { months, years } from '../utils/dates';
import { accountTypes } from '../utils';

export default {
  props: {
    options: Array,
    selectType: String,
  },
  methods: {
    selectOption(option, selectType) {
      this.$emit('input', option);
      this.$root.show[selectType] = false;
    },
    showOptions(options, selectType) {
      const possibleOptions = [
        states,
        accountTypes,
        months,
        years,
      ];
      return this.$root.show[selectType] && possibleOptions.includes(options);
    },
  },
};
</script>
