<template>
  <div>
    <script type="module" onload="window.threeDsPromiseResolver()" :src="scriptUrl"></script>
    <div v-show="iframeVisible" id="paay_iframe_container">
      <modal @close="closeIframe()"
        :success="false">
        <div slot="body">
          <iframe id="paay_iframe" name="3ds_iframe">
            <html>
              <body>
                  <form ref="iframe" name="3ds_form" target="3ds_iframe" :action="iframeAction" method="post">
                      <input v-for="(value, key) in iframeData" :name="key" :value="value" />
                  </form>
              </body>
            </html>
          </iframe>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import { statesLookup } from '../../../utils/states';
import config from '../../../config';
import Modal from '../../../components/modal.vue';

const threeDsPromise = new Promise((resolve, reject) => {
  window.threeDsPromiseResolver = resolve
})

export default {
  props: {
    value: { type: Object, default: () => ({}) },
    billingInfo: { type: Object, default: () => ({}) },
    shippingInfo: { type: Object, default: () => ({}) },
    recurringInfo: { type: Object, default: () => ({}) },
    amount: { type: Number, default: () => (null) },
    cardNumber: { type: String, default: () => ('') },
  },
  components: { Modal },
  data: () => ({
    schedules: {
      'daily': 1,
      'weekly': 7,
      'biweekly': 3,
      'monthly': 30,
      'bimonthly': 15,
      'quarterly': 91,
      'biannually': 182,
      'annually': 365
    },
    scriptUrl: config.paayLibrary.scriptUrl,
    cardDefaults: {
      cardholderName:'',
      company: '',
      expiryYear:'',
      expirymonth:'',
    },
    iframeData: {},
    iframeVisible: false,
    iframeAction: '',
    instance: null
  }),
  computed: {
    computedName () {
      const billingName = this.billingInfo.billingLastName && this.billingInfo.billingFirstName 
      ? this.billingInfo.billingFirstName + ' ' + this.billingInfo.billingLastName
      : this.billingInfo.billingLastName || this.billingInfo.billingFirstName
      return this.cardDetails.cardholderName || billingName || this.cardDetails.company || ''
    },
    addressMatch () {
      const comparedFields = ['shippingStreet', 'shippingStreet2', 'shippingState', 'shippingCity', 'shippingZipCode']
      return comparedFields.every(field =>
        this.billingInfo[field.replace('shipping', 'billing')] === this.shippingInfo[field]
      )
    },
    cardDetails () {
      return { ...this.cardDefaults, ...(this.value || {})}
    },
  },
  methods: {
    async init (provider) {
      if (this.instance) return
      await threeDsPromise
      this.instance = new window.ThreeDS({
        apiKey: provider.apiKey,
        frictionless: provider.frictionless,
        debug: config.paayLibrary.debug,
        baseUrl: config.paayLibrary.verifyUrl,
        logUrl: config.paayLibrary.logUrl,
        timeout: 3 * 60 * 1000,
        updateIframe: async (visible, url, data) => {
          this.iframeVisible = visible || false
          if (data) this.iframeData = data
          if (url) {
            this.iframeAction = url
            await this.$nextTick()
            this.$refs.iframe.submit()
          }
          if (this.iframeVisible) this.$root.loading = false
        }
      })
    },
    closeIframe () {
      this.instance.cancel()
    },
    stateLookup(state){
      return (state && statesLookup[state]) || ''
    },
    async authenticate () {
      const data = {
        pan: this.cardNumber,
        cardHolderName: this.computedName,
        year: this.cardDetails.expiryYear,
        month: this.cardDetails.expiryMonth,
        billing: {
          line1: this.billingInfo.billingStreet,
          line2: this.billingInfo.billingStreet2,
          state: this.stateLookup(this.billingInfo.billingState),
          city: this.billingInfo.billingCity,
          postCode: this.billingInfo.billingZipCode,
          country: '840'
        },
        shipping: {
          line1: this.shippingInfo.shippingStreet,
          line2: this.shippingInfo.shippingStreet2,
          state: this.stateLookup(this.shippingInfo.shippingState),
          city: this.shippingInfo.shippingCity,
          postCode: this.shippingInfo.shippingZipCode,
          country: '840'
        },
        email: (this.value && this.value.email) || '',
        merchantRiskIndicator: {
          shipIndicator: '07',
          deliveryTimeFrame: '01',
          reorderItemsInd: '01'
        },
        threeDSRequestorURL: config.paayLibrary.debug ? null : window.location.origin,
        messageCategory: '01',
        authenticationInd: '01',
        addrMatch: this.addressMatch ? 'Y' : 'N',
        amount: this.amount
      }
      if(this.recurringInfo?.schedule) {
        const forever = this.recurringInfo.remTransNumber === '*'
        const today = new Date();
        const frequency = this.schedules[this.recurringInfo.schedule]
        data.authenticationInd = '02'
        data.recurringExpiry = forever 
        ? '99991231' 
        : today.setDate(frequency * this.recurringInfo.remTransNumber) && today.toISOString().split('T')[0].replace(/-/g, '')
        data.recurringFrequency = frequency.toString()
      }
      const result = await this.instance.verify(data)
      return {
        dsTransId: result.dsTransId,
        cavv: result.cavv || result.authenticationValue,
        eci: result.eci
      }
    }
  }
}
</script>
<style>
#paay_iframe{
  width: 100%;
  height: 100%;
}
</style>
