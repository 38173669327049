import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import App from './App.vue';
import './utils/validationConfig';
import CustomComponent from './components/custom-component.vue';

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.component('custom-component', CustomComponent);

new Vue({
  el: '#app',
  data: {
    loading: false,
    activeTab: 'card',
    accountTypes: ['Checking', 'Savings'],
    availableTabs: [],
    termsAndConditions: null,
    surcharge: {
      card: {
        type: 'currency',
        fee: 0,
        optional: false,
      },
      check: {
        type: 'currency',
        fee: 0,
        optional: false,
      },
    },
    surchargeControl: {},
    /** The result of checking the BIN of the card. */
    surchargeData: {},
    action: null,
    show: {
      billingState: false,
      shippingState: false,
      accountType: false,
      expiryMonth: false,
      expiryYear: false,
      dafExpiryMonth: false,
      dafExpiryYear: false,
      dafCardNumber: false,
      dafCardholderName: false,
      dafCvv2: false,
      remTransNumber: false,
      schedule: false,
      amount: false,
      description: false
    },
    showCurrencySymbol: true,
    totalAmount: null,
    template: null,
    supportedWallets: {},
    formData: {
      // The API supports billing fields to be in its own object, but it works in generalInfo as well.
      generalInfo: {
        subtotal: null,
        description: null
      },
      customFields: {},
      billingInfo: {},
      shippingInfo: {},
      recurringInfo: {
        remTransNumber: null,
        schedule: null
      },
      paymentPage: {
        slug: null,
        isoWebsite: null,
      },
      recaptchaToken: null,
    },
  },
  render: h => h(App),
});

