<template>
  <div
      :id="amountItem && amountItem.attributes && amountItem.attributes.id"
      :class="computedSurcharge && hasDropdown ? [amountItem.classes.filter(cls => cls !== 'select-wrap'), 'surcharge-fields'] : amountItem.classes || 'amount-wrap'"
      v-if="$root.action !== 'save'">
    <validation-provider
        tag="div"
        :rules="{ amountRegex: generalInfo.subtotal, required: true }"
        :class="[(amountItem.components && amountItem.components[0] && amountItem.components[0].classes), 'input-wrap']"
        vid="amount"
        :name="labelName(amountLabel) || 'Amount'"
        v-slot="{ errors }">
      <input placeholder="0.00"
             type="text"
             id="amount"
             name="amount"
             v-bind="amountInput && amountInput.attributes && amountInput.attributes.readonly === '1' ? { readonly: 'readonly' } : ''"
             @click="hasDropdown && toggleShowItem(amountInput)"
             @input="onValueInput"
             @blur="setDefaultValue"
             :value="amountValue"
             :class="['active', 'currency', { 'text-indent': !computedSurcharge && hasDropdown && $root.showCurrencySymbol }]" />
      <label :id="amountLabel && amountLabel.attributes && amountLabel.attributes.id"
             for="amount"
             class="placeholder required">{{ labelName(amountLabel) || 'Amount' }}
      </label>
      <span
          v-show="$root.showCurrencySymbol"
          class="icon-currency icon-btn">
        <span class="currency"></span>
      </span>
      <span v-if="hasDropdown"
            class="icon icon-btn amount"
            @click="toggleShowItem(amountInput)">
        <span class="caret amount"></span>
      </span>
      <ul v-show="hasDropdown && $root.show['amount']"
          @input="$emit('input', $event)"
          class="dropdown">
        <li v-for="(item, index) in dropdownComponent"
            v-bind="item.attributes"
            :key="index"
            :name="item.attributes.name"
            :value="item.attributes.value"
            @click="selectOption({ key: item.attributes.name || item.attributes.value, value: item.attributes.value }, 'amount')">
        {{ item.attributes.name || item.components?.[0]?.content || item.attributes.value }}
        </li>
      </ul>
      <div class="error-wrapper">
        <span class="input-error">{{errors[0]}}</span>
      </div>
    </validation-provider>
  <validation-provider
      v-if="computedSurcharge"
      v-show="Boolean(this.surchargeForTab.value || this.surchargeForTab.fee)"
      tag="div"
      class="input-wrap surcharge-wrapper"
      :name="labelName(surchargeLabel) || 'Fee'"
      vid="surcharge"
      v-slot="{ errors }">
    <input readonly
           id="surcharge"
           name="surcharge"
           :value="surchargeAmountForTab"
           class="active" />
    <label :id="surchargeLabel && surchargeLabel.attributes && surchargeLabel.attributes.id"
           for="surcharge"
           class="placeholder">{{ labelName(surchargeLabel) || 'Fee' }}
    </label>
    <span class="currSymbol icon-currency icon-btn"
         v-show="surchargeForTab && surchargeForTab.type === 'currency'">
        <span class="currency"></span>
    </span>
    <span class="percentSymbol icon icon-btn"
         v-show="surchargeForTab && surchargeForTab.type === 'percent'">
        <span class="percent"></span>
    </span>
    <div class="error-wrapper">
      <span class="input-error">{{errors[0]}}</span>
    </div>
  </validation-provider>
  <validation-provider
      v-if="computedSurcharge"
      v-show="Boolean(this.surchargeForTab.value || this.surchargeForTab.fee)"
      tag="div"
      class="input-wrap active total-amount-wrapper"
      :name="labelName(totalAmountLabel) || 'Total'"
      vid="totalAmount"
      v-slot="{ errors }">
    <input id="totalAmount"
           name="totalAmount"
           :value="amountTotal"
           class="active currency"/>
    <label :id="totalAmountLabel && totalAmountLabel.attributes && totalAmountLabel.attributes.id"
           for="totalAmount"
           class="placeholder">{{ labelName(totalAmountLabel) || 'Total' }}
    </label>
    <span class="icon-currency icon-btn"><span class="currency"></span></span>
    <div class="error-wrapper">
      <span class="input-error">{{errors[0]}}</span>
    </div>
  </validation-provider>
  </div>
</template>

<script>
import { camelToLabel } from '../utils'

export default {
  props: {
    amountItem: undefined,
    surchargeData: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      amountValue: null
    }
  },
  watch: {
    'generalInfo.subtotal': function () {
      this.setAmounts()
    },
    'generalInfo.amount': function () {
      // added because need to pass in total amount for recurring
      this.setAmounts()
    },
    // todo: work on better state handling
    activeTab() {
      this.setAmounts()
    }
  },
  created() {
    this.setDefaultValue()
  },
  computed: {
    dropdownComponent() {
      return this.amountItem?.components?.[0]?.components
          ?.find(comp => comp.classes.some(cls => cls === 'dropdown'))?.components
    },
    generalInfo() {
      return this.$root.formData.generalInfo;
    },
    activeTab() {
      return this.$root.activeTab === 'check' ? 'check' : 'card';
    },
    surchargeForTab() {
      return this.computedSurcharge?.[this.activeTab] || {};
    },
    computedSurchargeControl () {
      return (this.$root.surchargeControl?.required?.paymentPage || this.$root.surchargeControl?.compliant) && this.$root.surchargeControl
    },
    computedSurcharge () {
      const cardSurcharge = (this.computedSurchargeControl?.card?.value && this.computedSurchargeControl?.card) ||
          (this.$root.surcharge?.card?.fee && this.$root.surcharge?.card)
      const checkSurcharge = (this.computedSurchargeControl?.check?.value && this.computedSurchargeControl?.check) ||
          (this.$root.surcharge?.check?.fee && this.$root.surcharge?.check)
      return Object.assign({}, { card: { ...cardSurcharge }, check: { ...checkSurcharge } })
    },
    surchargeAmountForTab() {
      const surcharge = this.computedSurcharge?.[this.activeTab]
      if (this.activeTab !== 'check') {
        if (this.surchargeData?.bin_type === 'D' || this.surchargeData?.bin_type === null) return 0
        if (this.surchargeData?.surcharge?.value) return this.surchargeData?.surcharge?.value
      }
      const value = surcharge?.value || surcharge?.fee || 0;
      return surcharge?.type === 'currency' ? value.toFixed(2) : value
    },
    amountTotal() {
      const amount = Number(this.generalInfo.subtotal);
      const surchargeTotal = Number(this.surchargeTotal) || 0;
      return amount ? (amount + surchargeTotal).toFixed(2) : (0).toFixed(2);
    },
    surchargeTotal() {
      const { subtotal } = this.generalInfo;
      const surcharge = this.activeTab !== 'check' && (this.surchargeData?.bin_type === 'D' || this.surchargeData?.bin_type === null)
          ? 0
          : this.surchargeForTab?.value || this.surchargeForTab?.fee;
      const isPercent = this.surchargeForTab?.type === 'percent';
      const value = isPercent
          ? (Number(subtotal) * (Number(surcharge) / 100))
          : Number(surcharge) || 0
      return value.toFixed(2)
    },
    amountLabel() {
      const amountItem = this.amountItem?.components?.[0]
      return amountItem?.components?.find(comp => comp.type === 'label')
    },
    amountInput() {
      const amountItem = this.amountItem?.components?.[0]
      return amountItem?.components?.find(comp => comp.tagName === 'input')
    },
    surchargeLabel() {
      const surchargeItem = this.amountItem?.components?.[1]
      return surchargeItem?.components?.find(comp => comp.type === 'label')
    },
    totalAmountLabel() {
      const totalAmountItem = this.amountItem?.components?.[2]
      return totalAmountItem?.components?.find(comp => comp.type === 'label')
    },
    hasDropdown() {
      return this.amountItem?.components?.[0]?.components
          ?.find(comp => comp.classes.some(cls => cls === 'dropdown'))
    },
  },
  methods: {
    setAmountValue(value) {
      this.amountValue = typeof value === 'object' ? value.key : value || null;
      this.generalInfo.subtotal = typeof value === 'object' ? value.value : value || null;
      this.generalInfo.amount = this.amountTotal;
      if (typeof value === 'object') this.$root.showCurrencySymbol = false;
      return this.amountValue
    },
    selectOption(option) {
      this.$root.showCurrencySymbol = false;
      this.$root.show['amount'] = false;
      this.setAmountValue(option)
      this.$emit('input', { key: option.key, value: option.value })
    },
    onValueInput(event) {
      this.toggleShowItem(this.amountInput, false);
      const { value } = event.target
      this.$root.showCurrencySymbol = true;
      this.setAmountValue(value)
      this.$emit('input', this.generalInfo.subtotal)
    },
    setDefaultValue(event) {
      if (!event?.target?.value && this.amountInput?.attributes?.value) {
        const { content } = this.amountInput
        const { value } = this.amountInput.attributes
        return this.amountValue = this.setAmountValue(content && value
            ? { key: content, value }
            : content || value || 0)
      }
    },
    /**
     * @param item
     * @param {boolean} [value] - if set, the visibility will be set to this value. If not, it will be toggled.
     */
    toggleShowItem(item, value) {
      const id = typeof item === 'object' ? item.attributes.id : item
      this.$root.show[id] = value ?? !this.$root.show[id];
    },
    setAmounts() {
      this.generalInfo.amount = this.amountTotal;
      this.$root.totalAmount = this.generalInfo.amount;
      this.$root.formData.totalAmount = this.generalInfo.amount;
      this.generalInfo.surcharge = this.surchargeTotal;
      this.generalInfo.tax = 0 // currently don't support tax
    },
    labelName(item) {
      return item?.content
          || item?.components?.[0]?.content
          || camelToLabel(item?.attributes?.for);
    }
  }
};
</script>
