import { extend, setInteractionMode } from 'vee-validate';
import { email, required, regex, numeric, length, min_value } from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} is required',
});
extend('min_value', {
  ...min_value,
  message: '{_field_} must be at least {min}',
});
extend('regex', {
  ...regex,
  message: 'Invalid {_field_}',
});
extend('length', {
  ...length,
  message: '{_field_} must be {length} digits',
});
extend('numeric', {
  ...numeric,
  message: '{_field_} must be numeric',
});
extend('email', {
  ...email,
  message: '{_field_} must be a valid email',
});
extend('cvv2', {
  params: ['target'],
  validate(value, target) {
    const card = target?.target;

    if (card) {
      const isAmex = card.startsWith('34') || card.startsWith('37');
      const length = isAmex ? 4 : 3;
      if (value.length !== length) return `{_field_} must be ${length} digits`;
    }
    return true;
  },
});
extend('luhn10', {
  validate(value) {
    let nCheck = 0;
    let bEven = false;

    const _card = value?.replace(/\D/g, '');
    for (let n = _card.length - 1; n >= 0; n--) {
      const cDigit = _card.charAt(n);
      let nDigit = parseInt(cDigit, 10);
      if (bEven) {
        nDigit *= 2;
        if (nDigit > 9) {
          nDigit -= 9;
        }
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return nCheck % 10 === 0;
  },
  message: `{_field_} is invalid`,
});

// Note: maestro must be before visa and after discover, because the ranges overlap
const cardRegexes = {
  discover: /^(?:65|64[4-9]|6011)[0-9]*$/,
  maestro: /^(?:493698|50[0-8][0-9]{3}|5[6-9]|6)[0-9]*$/,
  visa: /^4[0-9]*$/,
  mastercard: /^5[1-5][0-9]*$/,
  amex: /^3[47][0-9]*$/,
  diners: /^3(?:0[0-5]|[689])[0-9]*$/,
  jcb: /^(?:2131|1800|35[0-9]{2})[0-9]*$/,
};
const dafRegexes = {
  pledger: /^659997[0-9]*$/,
  donors_fund: /^659{4}[0-9]*$/
}

const cardLengths = {
  amex: [15],
  diners: [14, 15, 16]
}

function cardIsValid (value) {
  const strippedValue = (value || '').split(' ')?.join('');
  const card = Object.entries(cardRegexes).find(([type, regex]) => regex.test(strippedValue));
  return card && (cardLengths[card[0]] || [16]).includes(strippedValue.length);
}

extend('cardRegex', {
  validate(value) {
    return cardIsValid(value)
  },
  message: `{_field_} is invalid`,
});

extend('dafRegex', {
  validate(value, args) {
    const strippedValue = value.split(' ')?.join('');
    return args[0].some(daf => dafRegexes[daf].test(strippedValue)) && strippedValue.length === 16
  },
  message: `{_field_} is invalid`,
})

const isValidAmount = (value, subtotal) => {
  if (subtotal < 0.01) return `{_field_} must be at least 0.01`;
  return /^\d*(\.\d{1,2})?$/.test(subtotal);
}

extend('amountRegex', isValidAmount, 'subtotal');

export { cardRegexes, dafRegexes, cardIsValid }
