<template>
  <img :src="src" />
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    src() {
      return `/static/icons/svg/${this.icon}.svg`;
    },
  },
};
</script>
