import Vue from 'vue';

/**
 * Sets every property to null.
 * This is instead of using `obj = {}`, which apparently does not transfer watches to the new object.
 * @param {Record<*, *>} obj
 * @returns {Record<*, null>}
 */
export function vueNullify(obj) {
  Object.keys(obj).forEach(key => Vue.set(obj, key, null));
}

/**
 * Round a money amount to 2 decimal places.
 * @param number
 * @returns {number}
 */
export function roundMoney(number) {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
}

export function camelToLabel(str = '') {
  return str
      .replace(/([A-Z])/g, ' $1').trim()
      // Convert the first letter to uppercase
      .replace(/^(.)(.*)$/, (match, p1, p2) => `${p1.toUpperCase()}${p2}`);
}

export const accountTypes = ['Checking', 'Savings'];
