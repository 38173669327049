<template>
  <payment-page />
</template>

<script>
import PaymentPage from './views/payment-page/index.vue';

export default {
  name: 'App',
  components: {
    PaymentPage,
  },
};
</script>
